











import { Component, Vue } from 'vue-property-decorator'

import EventSponsorForm from '@/partials/forms/Events/SponsorForm.vue'

@Component({
  components: {
    EventSponsorForm
  }
})
export default class EventSponsorEdit extends Vue {
}
