






























































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { mask } from 'vue-the-mask'

import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'

@Component({
  directives: { mask },
  components: {
    RichTextEditor,
    NotifyOnFailed,
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})

export default class SponsorForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) title!: string
  @Prop({ type: String }) eventSponsorId!: string

  done = true
  form: Record<string, any> = {
    sponsorId: '',
    eventId: '',
    category: '',
    confirm: '',
    errors: {
      sponsorId: '',
      eventId: '',
      category: '',
      confirm: ''
    }
  }

  categories = [
    {
      name: 'Diamante',
      value: 'Diamante'
    },
    {
      name: 'Ouro',
      value: 'Ouro'
    },
    {
      name: 'Prata',
      value: 'Prata'
    },
    {
      name: 'Bronze',
      value: 'Bronze'
    }
  ]

  sponsors = []

  created () {
    this.form.eventId = this.eventId

    axios.get('/event/sponsors/dropdown')
      .then(response => {
        this.sponsors = response.data.data
      })

    if (this.type === 'edit') {
      axios.get(`/event/${this.eventId}/sponsors/${this.eventSponsorId}`)
        .then(response => {
          const sponsor = camelCaseKeys(response.data.data, { deep: true })
          Object.keys(sponsor).forEach((key: string) => {
            this.form[key] = key in this.form ? sponsor[key] : this.form[key]
          })
        })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit'
      ? `/event/${this.eventId}/sponsors/${this.eventSponsorId}`
      : `/event/${this.eventId}/sponsors`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess () {
    const successVerb = this.type === 'edit' ? 'editado' : 'cadastrado'

    const flashMessage = {
      message: `O Patrocinador foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({
      name: 'EventSponsors',
      params: {
        id: this.eventId
      }
    }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      eventId: '',
      sponsorId: '',
      category: '',
      confirm: ''
    }
  }
}
