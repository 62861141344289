var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"pb-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid sm:grid-cols-2 lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-9",attrs:{"tag":"div","rules":"required","name":"sponsor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-select',{attrs:{"label":"Patrocinador"},model:{value:(_vm.form.sponsorId),callback:function ($$v) {_vm.$set(_vm.form, "sponsorId", $$v)},expression:"form.sponsorId"}},_vm._l((_vm.sponsors),function(sponsor){return _c('cv-select-option',{key:sponsor.name,attrs:{"value":sponsor.id,"invalid-message":_vm.form.errors.type || errors[0]}},[_vm._v(" "+_vm._s(sponsor.name)+" ")])}),1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-select',{attrs:{"label":"Categoria"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}},_vm._l((_vm.categories),function(category){return _c('cv-select-option',{key:category.name,attrs:{"value":category.value,"invalid-message":_vm.form.errors.type || errors[0]}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"w-full lg:w-1/2"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),_c('div',[(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)])])]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }